@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --paper-color: #ffffff;
  --toolbar-size: 3.6rem;
  --sidebar-size: 250px;
}

@layer components {
  .stretch-children * {
    @apply flex-auto;
  }
}

.prose>*{
  @apply mb-1
}
.prose ul{
  @apply list-disc list-inside
}
.prose ol{
  @apply list-decimal list-inside
}
.prose a{
  @apply underline
}
