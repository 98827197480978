.fpContent {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background: var(--light-bg-color);
}

.fpToolbar {
  width: 100%;
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--dark-color);
  color: var(--paper-color);
  padding: var(--spacing-m) var(--spacing-m);
  box-sizing: border-box;
  height: var(--toolbar-size);
}
.fpToolbar * {
  box-sizing: border-box;
}
.fpToolbar h1 {
  flex: auto;
  font-size: var(--font-size-xl);
  margin: 0 1rem 0;
}
