.inspector {
  margin-top: var(--spacing-l);
}
:root {
  --paper-color: #ffffff;
}

.riSection {
  background: var(--light-bg-color);
  padding: var(--spacing-m) var(--spacing-m);
  border-radius: 4px;
}

.riCard {
  background: var(--paper-color);
  border-radius: 4px;
  margin-bottom: 0.325rem;
  font-size: var(--font-size-s);
}
.riCardExpanded {
  border: 1px solid var(--darker-border-color);
}
.riRuleHead {
  display: flex;
  align-items: center;
  padding: var(--spacing-m) var(--spacing-m);
  cursor: pointer;
  border-radius: 4px;
  border: 2px solid transparent;
}
.riRuleHead:hover {
  border-color: var(--semi-transparent-accent-color);
  /*background: rgba(var(--accent-color-rgb-components), 0.01);*/
}
.riRuleHeadIcon {
  display: inline-flex;
  width: 0.75rem;
  height: 0.75rem;
  margin-right: 0.5rem;
  color: var(--light-body-color);
}
.riRuleHeadIconOpen {
  transform: rotate(90deg);
}
.riRuleLabel {
  font-size: var(--font-size-s);
  font-weight: 500;
  flex: auto;
}
.riBadge {
  color: var(--accent-color);
  font-weight: 400;
  padding: 0.1625rem 0.3rem;
  border-radius: 6px;
}
.riBadgePrimary {
  color: var(--primary-color);
  font-weight: 400;
  padding: 0.1625rem 0.3rem;
  border-radius: 6px;
}

.riRuleDetails {
  padding: var(--spacing-s) var(--spacing-m) var(--spacing-m);
}

.riConditionsHeading {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 500;
}

.riConditionGroup {
  padding: var(--spacing-s) var(--spacing-s);
  /*background: var(--lighter-bg-color);*/
  border: 2px dashed var(--darker-border-color);
  margin-bottom: 6px;
  border-radius: 6px;
  position: relative;
  margin-top: 20px;
}
.riGroupBtnWrapper {
  position: relative;
  top: calc(-1 * var(--spacing-s) - 0.7rem);
  left: calc(-1 * var(--spacing-s) - 2px);
}
.riGroupBtn {
  border: none;
  background: var(--paper-color);
  border: 2px solid var(--dark-color);
  color: var(--dark-color);
  display: inline-flex;
  align-items: center;
  border-radius: 12px 12px 12px 0;
  padding: 4px 10px;
  font-weight: 600;
}
.riGroupBtnIcon {
  display: inline-flex;
  width: 0.7rem;
  height: 0.7rem;
  margin-left: 0.25rem;
}

.riConditionRow {
  display: flex;
  align-items: flex-start;
  background: var(--lighter-bg-color);
  padding: var(--spacing-s) var(--spacing-s);
  border-radius: 6px;
  margin-bottom: 6px;
}
.riConditionRow > div:not(:last-child) {
  margin-right: 2px;
}
.riConditionRow label {
  display: none;
}
.riConditionRowFrom,
.riConditionRowTo {
  flex-grow: 1;
  flex-shrink: 1;
  max-width: 40%;
}
.riConditionRowIcon {
  flex-grow: 0;
  flex-shrink: 0;
  padding-top: 0.8rem;
  color: var(--light-body-color);
}
.riConditionRowOp,
.riConditionRowDelete {
  flex-grow: 0;
  flex-shrink: 0;
}

.riConditionGroupActions {
  display: flex;
  justify-content: flex-end;
}

.riActionHeading {
  margin-top: 1rem;
  padding-top: 0.5rem;
  border-top: 1px solid var(--border-color);
  font-weight: 500;
  margin-bottom: 0;
}


.riRuleFooter {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 0.5rem;
}

.riJsonToolbar {
  display: flex;
  justify-content: flex-end;
  margin-top: 8px;
}
